import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ProfileAbout from '@/modules/profile/components/ProfileAbout.vue';
import ProfileDetail from '@/modules/profile/components/ProfileDetail.vue';
import ProfileHeader from '@/modules/profile/components/ProfileHeader.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-page',
    components: {
        ProfileAbout,
        ProfileDetail,
        ProfileHeader,
    },
    data() {
        return {
            workStationType: '',
            workStationBusinessName: '',
            workStationSubscriptionData: null,
            updatedactiveUserWorkstationDetail: null,
        };
    },
    computed: {
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(USERS_STORE, [
            'user',
            'accountSetupCache',
            'hasMainLayoutInitialized',
        ]),
        ...mapGetters(USER_WORKSTATION, ['userWorkstations', 'activeUserWorkstation']),
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
    },
    async created() {
        this.initializeWorkstationType();
        await this.initializeUserState();
    },
    methods: {
        ...mapActions(USERS_STORE, [
            'updateUserAccountSetupCache',
            'initializeUserState',
            'getUser',
            'setViewedUser'
        ]),
        async initializeWorkstationType() {
            const { authenticatedUser } = this;
            const { params } = this.$route;
            const { userId } = params;
            let viewedUser = null;
            // eslint-disable-next-line eqeqeq
            if (userId != authenticatedUser.userId) {
                window.localStorage.setItem('vw', `${userId}`);
                await this.getUser(userId).then((response) => {
                    this.setViewedUser(response);
                    viewedUser = response;
                }).catch(() => { });
            }
            if (this.activeUserWorkstation) {
                if (this.activeUserWorkstation?.subscription) {
                    const data = JSON.parse(this.activeUserWorkstation?.subscription);
                    if (data) {
                        const { metadata } = data;
                        // this.setSubscription(metadata);
                        this.workStationType = metadata.userWorkstationType;
                        this.workStationSubscriptionData = data;
                        this.workStationBusinessName = this.activeUserWorkstation?.name;
                    }
                }
                else {
                    this.workStationType = 'sole-trader'; // default ws is always soletrader
                }
                if (viewedUser) {
                    this.workStationBusinessName = viewedUser
                        ? `${viewedUser.firstName} ${viewedUser.lastName}`
                        : `${this.activeUserWorkstation?.user.firstName} ${this.activeUserWorkstation?.user.lastName}`;
                }
            }
            this.updatedactiveUserWorkstationDetail = {
                ...this.activeUserWorkstation,
                workStationType: this.workStationType,
                workStationSubscriptionData: this.workStationSubscriptionData,
                workStationBusinessName: this.workStationBusinessName
            };
        }
    },
});
