import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ProfileHeaderMenu from '@/modules/profile/components/ProfileHeaderMenu.vue';
import ProfileVerifyButton from '@/modules/profile/components/ProfileVerifyButton.vue';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-header',
    components: {
        ProfileHeaderMenu,
        ProfileVerifyButton,
    },
    data() {
        return {
            isProfileReady: false
        };
    },
    watch: {
        accountStatuses: {
            immediate: true,
            deep: true,
            handler() {
                this.isProfileReady = this.isProfileVerifiable;
            }
        }
    },
    async created() {
        await this.getAccountStatuses();
    },
    computed: {
        ...mapGetters(USERS_STORE, ['accountStatuses', 'user', 'isProfileVerifiable']),
        ...mapGetters(['isTradesperson']),
    },
    methods: {
        ...mapActions(USERS_STORE, ['getAccountStatuses']),
    }
});
