import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
// import AdditionalDetailsComponent from '@/modules/profile/components/AdditionalDetailsComponent.vue';
import ProfileBaseDialog from '@/modules/profile/components/dialog/ProfileBaseDialog.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-detail',
    props: ['workstationType', 'data'],
    components: {
        ProfileBaseDialog,
    },
    data() {
        return {
            type: '',
            openDialog: false,
            editBusinessName: false,
            businessForm: {
                id: null,
                type: '',
                name: '',
                vat: false,
                companyNumber: '',
                businessAddress: '',
                businessPostcode: '',
                preferredDistance: ''
            },
            form: {
                name: '',
                companyNumber: '',
                vatNumber: ''
            },
            isVatFormVisible: false,
            submitting: false,
            viewOnly: false
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user', 'viewedUser']),
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        businessName() {
            return this.data?.workStationBusinessName;
        },
        companyNumber() {
            return `${this.data?.workStationSubscriptionData.companyNumber}`;
        },
    },
    beforeUnmount() {
        window.localStorage.removeItem('vw');
    },
    async created() {
        await this.initialize();
    },
    methods: {
        ...mapActions(USERS_STORE, [
            'getBusinessDetails',
            'saveBusinessDetails',
            'updateBusinessDetails',
            'getUser'
        ]),
        async initialize() {
            const { user, authenticatedUser } = this;
            const { params } = this.$route;
            const userId = params.userId || user.id;
            let owner = user;
            // eslint-disable-next-line eqeqeq
            if (userId != authenticatedUser.userId) {
                window.localStorage.setItem('vw', `${userId}`);
                this.viewOnly = !this.viewOnly;
                await this.getUser(userId).then((response) => {
                    owner = response;
                }).catch(() => { });
            }
            if (owner?.userBusinessDetails) {
                this.getBusinessDetails({
                    userId: owner.id,
                    userBusinessDetailsId: owner.userBusinessDetails.id
                })
                    .then((response) => {
                    const variationName = (response.businessName || response.companyName) || response.teamName;
                    this.businessForm.id = response.id;
                    this.businessForm.type = response.type;
                    this.businessForm.name = variationName;
                    this.businessForm.vat = response.vat;
                    this.businessForm.businessAddress = response.businessAddress;
                    this.businessForm.businessPostcode = response.businessPostcode;
                    this.businessForm.preferredDistance = response.preferredDistance;
                    this.form.vatNumber = response.taxReferenceNumber;
                    this.form.name = variationName;
                    this.form.companyNumber = response.companyNumber;
                    const hasVat = Boolean(response.vat);
                    this.toggleVatForm(hasVat);
                })
                    .finally(() => {
                });
            }
        },
        onToggleDialog(type) {
            this.type = type;
            this.openDialog = !this.openDialog;
        },
        onToggleBusinessNameEdit() {
            if (this.editBusinessName) {
                this.onBusinessSave(null);
            }
            this.editBusinessName = !this.editBusinessName;
        },
        onBusinessSave(vat) {
            const { user, businessForm, form } = this;
            const data = {
                type: businessForm.type,
                // companyName: '',
                // nationalInsuranceNumber: '',
                taxReferenceNumber: form.vatNumber,
                companyNumber: businessForm.companyNumber,
                businessAddress: businessForm.businessAddress,
                businessPostcode: businessForm.businessPostcode,
                preferredDistance: businessForm.preferredDistance
                // incorporationDate: '',
            };
            if (vat !== null) {
                data.vat = this.isVatFormVisible;
            }
            if (this.workstationType === 'company') {
                data.companyName = form.name;
            }
            else if (this.workstationType === 'team') {
                data.teamName = form.name;
            }
            else {
                data.businessName = form.name;
            }
            if (businessForm.id) {
                this.updateBusinessDetails({
                    id: businessForm.id,
                    userId: user.id,
                    formData: data
                })
                    .then(async (response) => {
                    this.$notify.success({
                        message: 'Business Details successfully saved.'
                    });
                    this.businessForm.name = response?.businessName;
                    if ('vat' in response && response.vat !== null) {
                        this.businessForm.vat = response?.vat;
                    }
                })
                    .catch(() => {
                    this.$notify.error({
                        message: 'Error submitting business details. Please try again.'
                    });
                })
                    .finally(() => {
                    // this.submittingForm = false;
                });
            }
        },
        toggleVatForm(data) {
            if (!this.viewOnly) {
                this.isVatFormVisible = data;
            }
        }
    }
});
