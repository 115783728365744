import { defineComponent } from 'vue';
export default defineComponent({
    name: 'profile-header-menu',
    data() {
        return {
            menuItems: [
                'About',
                'Detail',
                'Reviews',
            ]
        };
    },
    methods: {
        isMenuActive(menu) {
            const { $route } = this;
            const { path } = $route;
            const segment = path.split('/');
            return segment.includes(menu.toLowerCase());
        },
        onNavigate(menu) {
            const name = `profile/${menu.toLowerCase()}`;
            return this.$router.push({ name });
        }
    }
});
